import { css, keyframes } from '@emotion/react'

const animationSpinning = keyframes`
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  } 
`

export const loader = css`
  animation-name: ${animationSpinning};
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
`