import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from '~/styles/colors'
import { boxShadow, transition } from '~/styles/constants'

const BORDER_WIDTH = '0.15rem'


export const button = ({ isOutlined, color, isDisabled }) => css`
  border: none;
  outline: none;
  background-color: ${colors[color]};
  color: ${colors.white};
  font-size: 1.2rem;
  border-radius: 3rem;
  padding: 0.5rem 1.5rem;
  font-family: inherit;
  cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
  ${transition};

  ${isOutlined && `
    background-color: unset;
    border: ${BORDER_WIDTH} solid ${colors[color]};
    padding: calc(0.5rem - ${BORDER_WIDTH} * 2) calc(1.5rem - ${BORDER_WIDTH} * 2);
    color: ${colors[color]};
  `};

  ${!isDisabled && `
    &:hover {
      ${isOutlined && `
        background-color: ${transparentize(0.5, colors[color])};
        color: ${colors.white};
      `};
      ${boxShadow};
    }
  `};
`

export const loader = css`
  width: 100%;
  height: 100%;
`