export const SEO = {
  title: 'حظي - تبصير و قراءة فنجا‪ن‬',
  description: 'حظي - قراءة فنجان وتاروت , قراءة خطوط الوجه (الفراسة العربية), فتح ورق التاروت, إستشارات عاطفية, تفسير الأحلام, إستشارات روحية. حظي - قراءة فنجان وتاروت أول تطبيق يضم أقوى علماء الفلك بخبرة عالية جداً على جميع المستويات من علم الفلك والدراسات الفلكية بحسابات دقيقة والإستشارات الفلكية بين البرجين وإستشارات نفسية مميزة جداً وأصول قراءة أوراق التاروت من أهم مختصين فيها كما تضم قراءة الفنجان وقراءة الوجوه ودراسة عميقة لأشكال الكف والرموز الذي تتواجد فيه كما يضم هذا التطبيق علماء مختصين بالقسم الروحاني والعلاج الفوري ويضم الرقوة من أهم أختصاصين بالعالم العربي والله أعلم.',
  robots: 'noodp',
  keywords: ',حظي, أبراج وعلم ألفلك ,قراءة فنجان القهوة ,قراءة الكف ,قراءة الوجه ومعالمه ,فتح ورق التاروت ,طلب إستشارة عاطفية ,طلب إستشارة روحية ,تفسير احلامكم ,رقيت العين و الحسد',
  url: 'https://store.hazziapp.net/',
  image: 'static/social-share.png',
  tileColor: '#24224E',
  tileImage: 'static/social-share.png',
  color: '#FF0000',
}

export const OG = {
  locale: 'ar_SA',
  type: 'website',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  siteName: SEO.title,
  image: SEO.image,
}

export const TWITTER = {
  card: 'summary',
  title: SEO.title,
  description: SEO.description,
  url: SEO.url,
  image: SEO.image,
}
