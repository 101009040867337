import React from 'react'

import Icon from '~/components/Icon'

import * as style from './styles'

/**
 * <Describe the Loader component here>
 *
 * @component
 * @usage import Loader from '~/components/Loader'
 * @example
 * <Add an example of how Loader is used here>
 */
const Loader = (props) => {
  return (
    <Icon icon='spinner' css={style.loader} {...props} />
  )
}

export default Loader
