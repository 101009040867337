import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Loader from '~/components/Loader'
import { isEmpty } from 'lodash'

import * as style from './styles'

/**
 * <Describe the Button component here>
 *
 * @component
 * @usage import Button from '~/components/Button'
 * @example
 * <Add an example of how Button is used here>
 */
const Button = ({ children, isDisabled, isLoading, isOutlined, color, ...props }) => {
  const buttonElement = useRef(null)
  const [buttonStyles, setButtonStyles] = useState({})

  useEffect(() => {
    if (isLoading) {
      setButtonStyles({
        width: buttonElement.current.clientWidth,
        height: buttonElement.current.clientHeight,
      })
    } else {
      setButtonStyles({})
    }
  }, [isLoading])

  return (
    <button
      ref={buttonElement}
      style={buttonStyles}
      disabled={isDisabled || isLoading}
      css={style.button({ isOutlined, color, isDisabled: isDisabled || isLoading })}
      {...props}
    >
      {isLoading && !isEmpty(buttonStyles) ? <Loader css={style.loader} /> : children}
    </button>
  )
}

Button.propTypes = {
  isOutlined: PropTypes.bool,
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

Button.defaultProps = {
  isOutlined: false,
  color: 'darkPurple',
  isDisabled: false,
  isLoading: false,
}

export default Button
